import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const DeviceModels = () => {
  const [modelsList, setModelsList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [manufacturersList, setManufacturersList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [manufacturerInput, setManufacturerInput] = useState("");
  const [nameInput, setNameInput] = useState("");

  const { callApi } = useApi();

  const navigate = useNavigate();

  const resetInputs = () => {
    setNameInput("");
    setManufacturerInput("");
  };

  const handleCancel = (_event) => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = {
        name: nameInput,
        manufacturerId: manufacturerInput,
      };
      const options = {
        path: "/device_models",
        scope: "create:device_models",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newDeviceModel = await callApi(options).then((res) => res.json());
      const newList = [...modelsList.data, newDeviceModel];
      setModelsList({
        ...modelsList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleEdit = async (model, _event) => {
    navigate(`/edit_device_model/${model.id}`);
  };

  useEffect(() => {
    const updateModelsList = async () => {
      try {
        const options = {
          path: "/device_models",
          scope: "list:device_models",
        };
        const newList = await callApi(options).then((res) => res.json());
        setModelsList({
          ...modelsList,
          data: newList,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setModelsList({
          ...modelsList,
          error,
          loading: false,
        });
      }
    };

    const updateManufacturersList = async () => {
      try {
        const options = {
          path: "/manufacturers",
          scope: "list:manufacturers",
        };
        const newList = await callApi(options).then((res) => res.json());
        setManufacturersList({
          ...manufacturersList,
          data: newList,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setManufacturersList({
          ...manufacturersList,
          error,
          loading: false,
        });
      }
    };

    updateModelsList();
    updateManufacturersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (deviceModel) => {
    return (
      <td>
        <input
          type="button"
          value="Edit"
          onClick={(e) => handleEdit(deviceModel, e)}
        ></input>
      </td>
    );
  };

  const modelRows = modelsList.loading ? (
    <tr>
      <td colSpan="5">Loading...</td>
    </tr>
  ) : (
    modelsList.data
      ?.filter((model) =>
        manufacturerInput === ""
          ? true
          : model.manufacturerId === manufacturerInput
      )
      ?.filter((model) => model.name.includes(nameInput))
      .map((model) => (
        <tr key={model.id}>
          <td>{model.manufacturer}</td>
          <td>{model.name}</td>
          {actionBuilder(model)}
        </tr>
      ))
  );

  const manufacturerOptions = manufacturersList.data.map((manufacturer) => (
    <option key={manufacturer.id} value={manufacturer.id}>
      {manufacturer.name}
    </option>
  ));

  return (
    <div className="DeviceModels page">
      <h2>Device Models</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="deviceModel-manufacturer">Manufacturer</label>
          <select
            id="deviceModel-manufacturer"
            value={manufacturerInput}
            onChange={(e) => setManufacturerInput(e.currentTarget.value)}
          >
            <option value="">Select a manufacturer...</option>
            {manufacturerOptions}
          </select>
        </div>
        <div>
          <label htmlFor="deviceModel-name">Model Name</label>
          <input
            id="deviceModel-name"
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Manufacturer</th>
            <th>Model Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{modelRows}</tbody>
      </table>
    </div>
  );
};

export default DeviceModels;
