import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const Users = () => {
  const [userList, setUserList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [clientList, setClientList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [emailInput, setEmailInput] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [phoneInput, setPhoneInput] = useState("");
  const [clientInput, setClientInput] = useState("");

  const { callApi } = useApi();

  const navigate = useNavigate();

  const resetInputs = () => {
    setEmailInput("");
    setNameInput("");
    setPhoneInput("");
    setClientInput("");
  };

  const handleCancel = (_event) => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = {
        email: emailInput,
        name: nameInput,
        phone: phoneInput,
        clientId: clientInput,
      };
      const options = {
        path: "/users",
        scope: "create:users",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newUser = await callApi(options).then((res) => res.json());
      const newList = [...userList.data, newUser];
      setUserList({
        ...userList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleEdit = async (user, _event) => {
    const userId = encodeURIComponent(user.userId);
    navigate(`/edit_user/${userId}`);
  };

  useEffect(() => {
    const updateUserList = async () => {
      try {
        const options = {
          path: "/users",
          scope: "list:users",
        };
        const users = await callApi(options).then((res) => res.json());
        setUserList({
          ...userList,
          data: users,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setUserList({
          ...userList,
          error,
          loading: false,
        });
      }
    };

    const updateClientList = async () => {
      try {
        const options = {
          path: "/clients",
          scope: "list:clients",
        };
        const fetchedClients = await callApi(options).then((res) => res.json());
        const clients = fetchedClients.map(({ clientId, clientName }) => ({
          clientId,
          clientName,
        }));
        setClientList({
          ...clientList,
          data: clients,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setClientList({
          ...clientList,
          error,
          loading: false,
        });
      }
    };

    updateUserList();
    updateClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (user) => {
    return (
      <td>
        <input
          type="button"
          value="Edit"
          onClick={(e) => handleEdit(user, e)}
        ></input>
      </td>
    );
  };

  const checkMatch = (value, input) => {
    return value.toLowerCase().includes(input.toLowerCase());
  };

  const userRows = userList.loading ? (
    <tr>
      <td colSpan="5">Loading...</td>
    </tr>
  ) : (
    userList.data
      ?.filter(
        (user) =>
          checkMatch(user.name, nameInput) && checkMatch(user.email, emailInput)
      )
      .map((user) => (
        <tr key={user.email}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.phone}</td>
          <td>{user.clientName}</td>
          {actionBuilder(user)}
        </tr>
      ))
  );

  const clientOptions = clientList.loading ? (
    <></>
  ) : (
    [<option key="">Select client...</option>].concat(
      clientList.data.map(({ clientId, clientName }) => (
        <option value={clientId} key={clientId}>
          {clientName}
        </option>
      ))
    )
  );

  return (
    <div className="Users page">
      <h2>Users</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="user-name">Name</label>
          <input
            id="user-name"
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="user-email">Email</label>
          <input
            id="user-email"
            type="email"
            value={emailInput}
            onChange={(e) => setEmailInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="user-phone">Phone</label>
          <input
            id="user-phone"
            type="tel"
            value={phoneInput}
            onChange={(e) => setPhoneInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="user-client">Client</label>
          <select
            value={clientInput}
            onChange={(e) => setClientInput(e.currentTarget.value)}
          >
            {clientOptions}
          </select>
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Client</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{userRows}</tbody>
      </table>
    </div>
  );
};

export default Users;
