import { useRef } from "react";
import { useNumberFieldState } from "react-stately";
import { useLocale, useNumberField } from "react-aria";

function Number({ label, onChange, value, constraints }) {
  let { locale } = useLocale();
  let formatOptions = useRef({
    maximumFractionDigits: constraints.maxFractionDigits,
  }).current;
  const props = {
    label,
    defaultValue: value,
    onChange,
    formatOptions,
    minValue: constraints?.minValue,
    maxValue: constraints?.maxValue,
  };
  let state = useNumberFieldState({ ...props, locale });
  let inputRef = useRef(null);
  let { labelProps, groupProps, inputProps } = useNumberField(
    props,
    state,
    inputRef
  );

  return (
    <div>
      <label {...labelProps}>{props.label}</label>
      <div {...groupProps}>
        <input {...inputProps} ref={inputRef} />
      </div>
    </div>
  );
}

export default Number;
