import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const EditDeviceType = () => {
  let params = useParams();

  const [deviceType, setDeviceType] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const [allParams, setAllParams] = useState([]);
  const [selectedParam, setSelectedParam] = useState("");

  const { callApi } = useApi();

  const handleAddParam = async (event) => {
    event.preventDefault();
    try {
      const options = {
        path: `/device_types/${params.deviceTypeId}/parameters`,
        scope: "update:device_types:parameters",
        method: "PUT",
        body: selectedParam,
      };
      const { parameters } = await callApi(options).then((res) => res.json());
      setDeviceType({
        ...deviceType,
        data: { ...deviceType.data, parameters },
      });
      setSelectedParam("");
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleRemoveParam = async (paramId, event) => {
    event.preventDefault();
    try {
      const options = {
        path: `/device_types/${params.deviceTypeId}/parameters/${paramId}`,
        scope: "update:device_types:parameters",
        method: "DELETE",
      };
      const { parameters } = await callApi(options).then((res) => res.json());
      setDeviceType({
        ...deviceType,
        data: { ...deviceType.data, parameters },
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const fetchDeviceType = async () => {
    try {
      const options = {
        path: `/device_types/${params.deviceTypeId}`,
        scope: "read:device_types",
      };
      const fetchedType = await callApi(options).then((res) => res.json());
      setDeviceType({
        ...deviceType,
        data: fetchedType,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDeviceType({
        ...deviceType,
        error,
        loading: false,
      });
    }
  };

  const fetchParameters = async () => {
    try {
      const options = {
        path: `/parameters`,
        scope: "list:parameters",
      };
      const parameters = await callApi(options).then((res) => res.json());
      setAllParams(parameters.filter((p) => p.category === "device type"));
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    fetchDeviceType();
    fetchParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paramRows = () => {
    if (deviceType.loading) {
      return (
        <tr>
          <td colSpan="5">Loading...</td>
        </tr>
      );
    }
    const params = deviceType.data.parameters;
    if (!params || params.length === 0) {
      return (
        <tr>
          <td colSpan="5">No parameters</td>
        </tr>
      );
    }
    const rows = [];
    for (const p of params) {
      rows.push(
        <tr key={p.id}>
          <td>{p.name}</td>
          <td>
            <button onClick={(event) => handleRemoveParam(p.id, event)}>
              Remove
            </button>
          </td>
        </tr>
      );
    }
    return rows;
  };

  const availableParams = () => {
    const existingParams = deviceType.data?.parameters || [];
    return allParams.filter((p) => !existingParams.find((e) => e.id === p.id));
  };

  return (
    <div className="EditDeviceType page">
      <h2>Parameters for {deviceType.data?.name}</h2>
      <form onSubmit={handleAddParam}>
        <select
          value={selectedParam}
          onChange={(e) => setSelectedParam(e.currentTarget.value)}
        >
          <option value="" key="">
            Select a Parameter...
          </option>
          {availableParams().map(({ id, name }) => (
            <option value={id} key={id}>
              {name}
            </option>
          ))}
        </select>
        <input type="submit" value="Add" disabled={selectedParam === ""} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Parameter Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{paramRows()}</tbody>
      </table>
    </div>
  );
};

export default EditDeviceType;
