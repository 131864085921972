import React from "react";
import ReactDOM from "react-dom/client";
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import "./index.css";
import Root from "./Root";
import AlertsList from "./components/views/devices/AlertsList";
import Clients from "./components/views/Clients";
import Users from "./components/views/Users";
import Devices from "./components/views/Devices";
import DeviceTypes from "./components/views/DeviceTypes";
import EditDevice from "./components/views/devices/EditDevice";
import EditDeviceType from "./components/views/EditDeviceType";
import EditUser from "./components/views/EditUser";
import Parameters from "./components/views/Parameters";
import { useNavigate, BrowserRouter, Routes, Route } from "react-router-dom";
import { LoadScript } from "@react-google-maps/api";
import { library as iconLibrary } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPlus,
  faBell,
  faPenToSquare,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Manufacturers from "./components/views/Manufacturers";
import DeviceModels from "./components/views/DeviceModels";
import EditDeviceModel from "./components/views/EditDeviceModel";
import DevicesList from "./components/views/devices/DevicesList";
import CreateAlert from "./components/views/devices/CreateAlert";
import CreateDevice from "./components/views/devices/CreateDevice";
import EditAlert from "./components/views/devices/EditAlert";

const withAdmin = (Component) => (returnTo) => {
  const { user } = useAuth0();
  const navigate = useNavigate();
  if (isAdmin(user)) {
    return <Component />;
  }
  navigate(returnTo);
};

const isAdmin = (user) => user?.cyf_roles?.includes("Admin");
// Usage
// const Page = withAuthenticationRequired(
//   withClaimCheck(Component, checkClaims, '/missing-roles' )
// );

const AdminRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(withAdmin(component, args));
  return <Component />;
};

const UserRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component, args);
  return <Component />;
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
iconLibrary.add(faTrash, faPlus, faBell, faPenToSquare, faPhone, faEnvelope);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          audience: process.env.REACT_APP_AUDIENCE,
          scope: "profile email",
          redirect_uri: window.location.origin,
        }}
        redirectUri={window.location.origin}
        useRefreshTokens={true}
        cacheLocation="localstorage"
        cookieDomain={process.env.REACT_APP_COOKIE_DOMAIN}
      >
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
          <Routes>
            <Route path="/" element={<Root />}>
              <Route
                path="clients"
                element={<AdminRoute component={Clients} />}
              />
              <Route path="devices" element={<Devices />}>
                <Route index element={<AdminRoute component={DevicesList} />} />
                <Route
                  path="create"
                  element={<AdminRoute component={CreateDevice} />}
                />
                <Route
                  path=":deviceId/edit"
                  element={<UserRoute component={EditDevice} />}
                />
                <Route
                  path=":deviceId/alerts"
                  element={<UserRoute component={AlertsList} />}
                />
                <Route
                  path=":deviceId/alerts/create"
                  element={<UserRoute component={CreateAlert} />}
                />
                <Route
                  path=":deviceId/alerts/:alertId/edit"
                  element={<UserRoute component={EditAlert} />}
                />
              </Route>
              <Route
                path="device_types"
                element={<AdminRoute component={DeviceTypes} />}
              />
              <Route
                path="edit_device_type/:deviceTypeId"
                element={<AdminRoute component={EditDeviceType} />}
              />
              <Route path="users" element={<AdminRoute component={Users} />} />
              <Route
                path="edit_user/:userId"
                element={<AdminRoute component={EditUser} />}
              />
              <Route
                path="parameters"
                element={<AdminRoute component={Parameters} />}
              />
              <Route
                path="manufacturers"
                element={<AdminRoute component={Manufacturers} />}
              />
              <Route
                path="device_models"
                element={<AdminRoute component={DeviceModels} />}
              />
              <Route
                path="edit_device_model/:deviceModelId"
                element={<AdminRoute component={EditDeviceModel} />}
              />
            </Route>
          </Routes>
        </LoadScript>
      </Auth0ProviderWithRedirectCallback>
    </BrowserRouter>
  </React.StrictMode>
);
