import { useRef, useState } from "react";
import Button from "../Button";

const CalibrationFile = ({ label, value, onChange }) => {
  const inputRef = useRef();
  const [replaceMode, setReplaceMode] = useState(false);

  const changeWrapper = async () => {
    const selectedFile = inputRef.current?.files[0];

    let volumeTable = {};

    try {
      const rawData = await selectedFile.text();
      // Filter out empty rows
      const rows = rawData.split("\r\n").filter((row) => !row.match(/^$/));
      const headers = rows.shift().split(",");
      headers.forEach((header) => header.trim());
      const levelIdx = headers.indexOf("level");
      const volumeIdx = headers.indexOf("volume");

      rows.forEach((row) => {
        const rowData = row.split(",");
        volumeTable[rowData[levelIdx].trim()] = rowData[volumeIdx].trim();
      });
    } catch (error) {
      console.log(JSON.stringify(error));
      return;
    }
    setReplaceMode(false);
    onChange(volumeTable);
  };

  const dataStored = !(value == null) && typeof value == "object";
  const defaultValue = dataStored ? "" : value || "";
  const storedRows = dataStored ? Object.keys(value).length : 0;
  // If data exists, show how many rows are stored and a "Replace" button
  // Replace button sets value to the empty string
  // If value is empty, then show file input

  return (
    <div>
      <label>{label}</label>
      {storedRows > 0 && !replaceMode ? (
        <div>
          <div>{storedRows.toString()} rows</div>
          <Button onPress={() => setReplaceMode(true)}>Upload New File</Button>
        </div>
      ) : (
        <input
          type="file"
          defaultValue={defaultValue}
          ref={inputRef}
          onChange={changeWrapper}
        />
      )}
    </div>
  );
};

export default CalibrationFile;
