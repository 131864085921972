import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecipientsManager = ({
  users = [],
  recipients = [],
  onRecipientsChange,
}) => {
  const [selectedRecipients, setSelectedRecipients] = useState(recipients);
  const [inputValue, setInputValue] = useState("");

  // Add a recipient (either from the user list or an arbitrary value)

  // TODO: Change recipient structure to: {type: 'email' or 'phone', userId: uuid or null, value: 'a@b.c' or '041234...'}
  const handleAddRecipient = (newRecipient) => {
    if (newRecipient.value) {
      const updatedRecipients = [...selectedRecipients, newRecipient];
      setSelectedRecipients(updatedRecipients);
      onRecipientsChange(updatedRecipients);
      setInputValue(""); // Clear the input after adding
    }
  };

  // Remove a recipient
  const handleRemoveRecipient = (value) => {
    const updatedRecipients = selectedRecipients.filter(
      (recipient) => recipient !== value
    );
    setSelectedRecipients(updatedRecipients);
    onRecipientsChange(updatedRecipients);
  };

  // Handle input for adding other values (emails/phone numbers)
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Detect Enter key to add other values
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleAddRecipient(inputValue);
    }
  };

  const disabledMap = selectedRecipients.map((r) => `${r.userId}-${r.type}`);

  return (
    <div>
      <ul className="recipients-list">
        {users.map((user) => (
          <li key={user.email}>
            <span>{user.name}</span>
            <div>
              <button
                className="icon-button"
                disabled={disabledMap.includes(`${user.id}-email`)}
                onClick={(e) => {
                  e.preventDefault();
                  let recipient = {
                    type: "email",
                    userId: user.id,
                    value: user.email,
                  };
                  handleAddRecipient(recipient);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-envelope" />
              </button>
              <button
                className="icon-button"
                disabled={disabledMap.includes(`${user.id}-phone`)}
                onClick={(e) => {
                  e.preventDefault();
                  let recipient = {
                    type: "phone",
                    userId: user.id,
                    value: user.phone,
                  };
                  handleAddRecipient(recipient);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-phone" />
              </button>
            </div>
          </li>
        ))}
      </ul>
      <div>
        {/* Input field to add othery values */}
        <input
          type="text"
          placeholder="Add other email/phone"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            let type = /.*@.*/.test(inputValue) ? "email" : "phone";
            let recipient = {
              type: type,
              userId: null,
              value: inputValue,
            };
            handleAddRecipient(recipient);
          }}
        >
          Add
        </button>
      </div>

      {/* Selected Recipients */}
      <h4>Selected Recipients:</h4>
      <ul>
        {selectedRecipients.map((recipient) => (
          <li key={recipient.value}>
            {recipient.value}
            <button
              onClick={(e) => {
                e.preventDefault();
                handleRemoveRecipient(recipient);
              }}
            >
              Remove
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecipientsManager;
