const errorLogger = (error) => {
  if (process.env.NODE_ENV === "development") {
    console.error(error);
  } else {
    // TODO: Implement a better logging backend for Prod
    console.error(error);
  }
};

export default errorLogger;
