import React, { useEffect, useState } from "react";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";
import LocationInput from "../elements/LocationInput";

const Clients = (props) => {
  // Name of the client to save
  const [nameInput, setNameInput] = useState("");
  const [tradingNameInput, setTradingNameInput] = useState("");
  const [locationInput, setLocationInput] = useState({});
  const [listResult, setListResult] = useState({
    error: null,
    loading: true,
    clientList: [],
  });

  const { callApi } = useApi();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const requestBody = {
      clientName: nameInput,
      tradingName: tradingNameInput,
      location: locationInput,
    };
    try {
      const options = {
        path: "/clients",
        scope: "create:clients",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newClient = await callApi(options).then((res) => res.json());
      const newList = [...listResult.clientList, newClient];
      setListResult({
        ...listResult,
        clientList: newList,
      });
      setNameInput("");
      setTradingNameInput("");
      setLocationInput({});
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleCancel = (_event) => setNameInput("");

  useEffect(() => {
    const updateClientList = async () => {
      try {
        const options = {
          path: "/clients",
          scope: "list:clients",
        };
        const clients = await callApi(options).then((res) => res.json());
        setListResult({
          ...listResult,
          clientList: clients,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setListResult({
          ...listResult,
          error,
          loading: false,
        });
      }
    };

    updateClientList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (client) => {
    return (
      <td>
        {/* <input type='button' value="Edit" onClick={(e) => handleEdit(client, e)}></input> */}
      </td>
    );
  };

  const clientRows = listResult.loading ? (
    <tr>
      <td colSpan="2">Loading...</td>
    </tr>
  ) : (
    listResult.clientList
      ?.filter((client) => client.clientName.includes(nameInput))
      .map((client) => (
        <tr key={client.clientName}>
          <td>{client.clientName}</td>
          <td>{client.tradingName}</td>
          <td>
            {client.location?.latitude && client.location?.longitude
              ? `${client.location?.latitude.toString()}, ${client.location?.longitude.toString()}`
              : ``}
          </td>
          {actionBuilder(client)}
        </tr>
      ))
  );

  return (
    <div className="Clients page">
      <h2>Clients</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Client Name:</label>
          <input
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <label>Trading Name:</label>
          <input
            type="text"
            value={tradingNameInput}
            onChange={(e) => setTradingNameInput(e.currentTarget.value)}
          />
        </div>
        <div>
          <LocationInput
            label="Default Location"
            onChange={setLocationInput}
            value={locationInput}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Trading Name</th>
            <th>Location</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{clientRows}</tbody>
      </table>
    </div>
  );
};

export default Clients;
