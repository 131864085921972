import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AlertsList = () => {
  const { user } = useAuth0();
  const userRoles = user?.cyf_roles;
  const isAdmin = userRoles && userRoles.includes("Admin");

  const { deviceId } = useParams();

  const [alertList, setAlertList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [device, setDevice] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const { callApi } = useApi();
  const navigate = useNavigate();

  const handleDelete = async (alert, event) => {
    event.preventDefault();

    try {
      const options = {
        path: `/alerts/${alert.id}`,
        scope: "delete:alerts",
        method: "DELETE",
      };
      await callApi(options);
      const newList = alertList.data.filter((a) => a.id !== alert.id);
      setAlertList({
        ...alertList,
        data: newList,
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const fetchDevice = async () => {
    try {
      const options = {
        path: `/devices/${deviceId}`,
        scope: "read:devices",
      };
      const newDevice = await callApi(options).then((res) => res.json());

      setDevice({
        ...device,
        data: newDevice,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDevice({
        ...device,
        error,
        loading: false,
      });
    }
  };

  const fetchAlerts = async () => {
    try {
      const options = {
        path: "/alerts",
        scope: "list:alerts",
        query: { deviceId: deviceId },
      };
      const alerts = await callApi(options).then((res) => res.json());
      setAlertList({
        ...alertList,
        data: alerts,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setAlertList({
        ...alertList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    fetchDevice();
    fetchAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformConditionValue = (value) => {
    if (value === "high") {
      return "Greater Than";
    } else if (value === "low") {
      return "Less Than";
    } else {
      return value;
    }
  };

  const alertRows = alertList.loading ? (
    <tr>
      <td colSpan="7">Loading...</td>
    </tr>
  ) : (
    alertList.data.map((alert) => (
      <tr key={alert.id} className={alert.state}>
        <td data-label="Target Field">{alert.targetField}</td>
        <td data-label="Condition">
          {transformConditionValue(alert.condition)}
        </td>
        <td data-label="Threshold">{alert.threshold}</td>
        <td data-label="Hits">{alert.hits}</td>
        <td data-label="Reset Buffer">{alert.resetDifference}</td>
        <td data-label="Recipients">
          {alert.recipients.map((r) => r.value).join(", ")}
        </td>
        <td data-label="Message">{alert.message}</td>
        <td data-label="Actions">
          <button
            className="icon-button"
            onClick={() =>
              navigate(`/devices/${deviceId}/alerts/${alert.id}/edit`)
            }
          >
            <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
          </button>
          <button
            className="icon-button"
            onClick={(e) => handleDelete(alert, e)}
          >
            <FontAwesomeIcon icon="fa-solid fa-trash" />
          </button>
        </td>
      </tr>
    ))
  );

  return (
    <div className="alerts-list">
      <h2>Alerts</h2>
      <h3>Device: {device.data.name}</h3>
      <button onClick={(_e) => navigate(`/devices/${deviceId}/alerts/create`)}>
        New Alert
      </button>
      <table className="responsive">
        <thead>
          <tr>
            <th scope="col">Target Field</th>
            <th scope="col">Condition</th>
            <th scope="col">Threshold</th>
            <th scope="col">Hits</th>
            <th scope="col">Reset Buffer</th>
            <th scope="col">Recipients</th>
            <th scope="col">Message</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>{alertRows}</tbody>
      </table>
      {isAdmin ? (
        <input
          type="button"
          value="Cancel"
          onClick={(_e) => navigate(`/devices/${deviceId}`)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AlertsList;
