import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const DeviceTypes = () => {
  const [typesList, setTypesList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [nameInput, setNameInput] = useState("");

  const { callApi } = useApi();

  const navigate = useNavigate();

  const resetInputs = () => {
    setNameInput("");
  };

  const handleCancel = (_event) => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = { name: nameInput };
      const options = {
        path: "/device_types",
        scope: "create:device_types",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newDeviceType = await callApi(options).then((res) => res.json());
      const newList = [...typesList.data, newDeviceType];
      setTypesList({
        ...typesList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleEdit = async (type, _event) => {
    navigate(`/edit_device_type/${type.id}`);
  };

  useEffect(() => {
    const updateTypesList = async () => {
      try {
        const options = {
          path: "/device_types",
          scope: "list:device_types",
        };
        const fetchedTypes = await callApi(options).then((res) => res.json());
        setTypesList({
          ...typesList,
          data: fetchedTypes,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setTypesList({
          ...typesList,
          error,
          loading: false,
        });
      }
    };

    updateTypesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (deviceType) => {
    return (
      <td>
        <input
          type="button"
          value="Edit"
          onClick={(e) => handleEdit(deviceType, e)}
        ></input>
      </td>
    );
  };

  const typeRows = typesList.loading ? (
    <tr>
      <td colSpan="5">Loading...</td>
    </tr>
  ) : (
    typesList.data
      ?.filter((type) => type.name.includes(nameInput))
      .map((type) => (
        <tr key={type.id}>
          <td>{type.name}</td>
          {actionBuilder(type)}
        </tr>
      ))
  );

  return (
    <div className="DeviceTypes page">
      <h2>Device Types</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="deviceType-name">Device Type</label>
          <input
            id="deviceType-name"
            type="text"
            value={nameInput}
            onChange={(e) => setNameInput(e.currentTarget.value)}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Device Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{typeRows}</tbody>
      </table>
    </div>
  );
};

export default DeviceTypes;
