import { useEffect, useState } from "react";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import { useNavigate } from "react-router-dom";

function CreateDevice() {
  const { callApi } = useApi();
  const navigate = useNavigate();

  const [typesList, setTypesList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const [clientsList, setClientsList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [manufacturersList, setManufacturersList] = useState({
    error: null,
    loading: true,
    data: [],
  });

  const [modelsList, setModelsList] = useState({
    error: null,
    loading: false,
    data: [],
  });

  const [formEui, setFormEui] = useState("");
  const [formName, setFormName] = useState("");
  const [formClient, setFormClient] = useState("");
  const [formManufacturer, setFormManufacturer] = useState("");
  const [formModel, setFormModel] = useState("");
  const [formDeviceType, setFormDeviceType] = useState("");

  const updateManufacturersList = async () => {
    try {
      const options = {
        path: "/manufacturers",
        scope: "list:manufacturers",
      };
      const res = await callApi(options);
      setManufacturersList({
        ...manufacturersList,
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setManufacturersList({
        ...manufacturersList,
        error,
        loading: false,
      });
    }
  };

  const updateTypesList = async () => {
    try {
      const options = {
        path: "/device_types",
        scope: "list:device_types",
      };
      const res = await callApi(options);
      setTypesList({
        ...typesList,
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setTypesList({
        ...typesList,
        error,
        loading: false,
      });
    }
  };

  const updateClientsList = async () => {
    try {
      const options = {
        path: "/clients",
        scope: "list:clients",
      };
      const newList = await callApi(options).then((res) => res.json());
      setClientsList({
        ...clientsList,
        data: newList,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setClientsList({
        ...clientsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    updateManufacturersList();
    updateTypesList();
    updateClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateModelsList = async () => {
    try {
      const options = {
        path: "/device_models",
        scope: "list:device_models",
        query: { manufacturerId: formManufacturer },
      };
      const res = await callApi(options);
      setModelsList({
        data: await res.json(),
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setModelsList({
        ...modelsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (!modelsList.loading && !!formManufacturer) {
      setModelsList({
        ...modelsList,
        loading: true,
      });
      updateModelsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formManufacturer]);

  const handleAddDevice = async (event) => {
    event.preventDefault();
    if (
      ![
        formEui,
        formName,
        formClient,
        formManufacturer,
        formModel,
        formDeviceType,
      ].every((v) => v !== "")
    ) {
      alert("OH NO! Form is incomplete!");
      return;
    }

    console.log(formClient);
    console.log(clientsList.data);
    const selectedClient = clientsList.data.find(
      (c) => c.clientId === formClient
    );

    console.log(selectedClient);

    const requestBody = {
      eui: formEui.trim(),
      name: formName.trim(),
      clientId: formClient,
      location: selectedClient.location,
      modelId: formModel,
      deviceTypeId: formDeviceType,
    };

    try {
      const options = {
        path: `/devices`,
        scope: "create:devices",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newDevice = await callApi(options).then((res) => res.json());
      navigate(`/devices/${newDevice.id}/edit`);
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleCancel = () => navigate(`/devices`);

  return (
    <div className="Devices page">
      <h2>Create Device</h2>
      <form onSubmit={handleAddDevice}>
        <div>
          <label>EUI</label>
          <input
            type="text"
            value={formEui}
            onChange={(e) => setFormEui(e.currentTarget.value)}
          ></input>
        </div>
        <div>
          <label>Name</label>
          <input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.currentTarget.value)}
          ></input>
        </div>
        <div>
          <label>Client</label>
          <select
            value={formClient}
            onChange={(e) => setFormClient(e.currentTarget.value)}
          >
            <option>Select client...</option>
            {clientsList.data.map((c) => (
              <option key={c.clientId} value={c.clientId}>
                {c.clientName}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Manufacturer</label>
          <select
            value={formManufacturer}
            onChange={(e) => setFormManufacturer(e.currentTarget.value)}
          >
            <option>Select manufacturer...</option>
            {manufacturersList.data.map((m) => (
              <option key={m.id} value={m.id}>
                {m.name}
              </option>
            ))}
          </select>
        </div>
        {!modelsList.loading && modelsList.data.length ? (
          <div>
            <label>Device Model</label>
            <select
              value={formModel}
              onChange={(e) => setFormModel(e.currentTarget.value)}
            >
              <option>Select model...</option>
              {modelsList.data.map((m) => (
                <option key={m.id} value={m.id}>
                  {m.name}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        <div>
          <label>Device Type</label>
          <select
            value={formDeviceType}
            onChange={(e) => setFormDeviceType(e.currentTarget.value)}
          >
            <option>Select device type...</option>
            {typesList.data.map((t) => (
              <option key={t.id} value={t.id}>
                {t.name}
              </option>
            ))}
          </select>
        </div>
        <input type="submit" value="Add Device" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>
    </div>
  );
}

export default CreateDevice;
