import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function DropdownConstraints({
  inputValueConstraints,
  setInputValueConstraints,
}) {
  const [typeInput, setTypeInput] = useState("");
  const [keyInput, setKeyInput] = useState("");
  const [valueInput, setValueInput] = useState("");
  const [schemaKeyInput, setSchemaKeyInput] = useState("");
  const [schemaTypeInput, setSchemaTypeInput] = useState("");
  const [schema, setSchema] = useState({ name: "String" });

  const valueInputsForDropdownType = (type) => {
    const listItemsBuilder = (items) => {
      if (Array.isArray(items)) {
        return items.map((item) => {
          if (item.hasOwnProperty("name")) {
            return (
              <li key={item.name}>
                {JSON.stringify(item)}
                <button
                  onClick={() => {
                    const newConstraints = inputValueConstraints.filter(
                      (c) => c.name !== item.name
                    );
                    setInputValueConstraints(newConstraints);
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
              </li>
            );
          } else {
            return (
              <li key={item}>
                {item}
                <button
                  onClick={() => {
                    const newConstraints = inputValueConstraints.filter(
                      (v) => v !== item
                    );
                    setInputValueConstraints(
                      newConstraints.length > 0 ? newConstraints : {}
                    );
                  }}
                >
                  <FontAwesomeIcon icon="fa-solid fa-trash" />
                </button>
              </li>
            );
          }
        });
      } else {
        return Object.entries(items).map(([key, value]) => {
          return (
            <li key={key}>
              {key} : {JSON.stringify(value)}
              <button
                onClick={() => {
                  const newConstraints = { ...inputValueConstraints };
                  delete newConstraints[key];
                  setInputValueConstraints(newConstraints);
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-trash" />
              </button>
            </li>
          );
        });
      }
    };

    const typeInputsBuilder = (type, id, value, setter) => {
      const map = {
        String: (
          <input
            id={id}
            type="text"
            value={value}
            onChange={(e) => setter(e.currentTarget.value)}
          />
        ),
        Number: (
          <input
            id={id}
            type="number"
            value={value}
            onChange={(e) => setter(e.currentTarget.value)}
          />
        ),
        Boolean: (
          <select
            id={id}
            value={value}
            onChange={(e) => setter(e.currentTarget.value)}
          >
            <option key="Prompt">Select a value...</option>
            <option key="True" value="true">
              True
            </option>
            <option key="False" value="false">
              False
            </option>
          </select>
        ),
      };
      return map[type] || <></>;
    };

    const typeMap = {
      List: (values) => {
        return (
          <div>
            <ul>{listItemsBuilder(values)}</ul>
            <input
              id="parameters-dropdown-values"
              type="text"
              value={valueInput}
              onChange={(e) => setValueInput(e.currentTarget.value)}
            />
            <button
              onClick={() => {
                setInputValueConstraints(
                  Array.isArray(inputValueConstraints)
                    ? inputValueConstraints.concat(valueInput)
                    : [valueInput]
                );
                setValueInput("");
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
        );
      },
      String: (values) => {
        return (
          <div>
            <ul>{listItemsBuilder(values)}</ul>
            <input
              id="parameters-dropdown-values-key"
              type="text"
              value={keyInput}
              onChange={(e) => setKeyInput(e.currentTarget.value)}
            />
            {typeInputsBuilder(
              "String",
              "parameters-dropdown-values-value",
              valueInput,
              setValueInput
            )}
            <button
              onClick={() => {
                const newConstraints = inputValueConstraints;
                newConstraints[keyInput] = valueInput;
                setInputValueConstraints(newConstraints);
                setKeyInput("");
                setValueInput("");
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
        );
      },
      Number: (values) => {
        return (
          <div>
            <ul>{listItemsBuilder(values)}</ul>
            <input
              id="parameters-dropdown-values-key"
              type="text"
              value={keyInput}
              onChange={(e) => setKeyInput(e.currentTarget.value)}
            />
            {typeInputsBuilder(
              "Number",
              "parameters-dropdown-values-value",
              valueInput,
              setValueInput
            )}
            <button
              onClick={() => {
                const newConstraints = inputValueConstraints;
                newConstraints[keyInput] = parseFloat(valueInput);
                setInputValueConstraints(newConstraints);
                setKeyInput("");
                setValueInput("");
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
        );
      },
      Boolean: (values) => {
        return (
          <div>
            <ul>{listItemsBuilder(values)}</ul>
            <input
              id="parameters-dropdown-values-key"
              type="text"
              value={keyInput}
              onChange={(e) => setKeyInput(e.currentTarget.value)}
            />
            {typeInputsBuilder(
              "Boolean",
              "parameters-dropdown-values-value",
              valueInput,
              setValueInput
            )}
            <button
              onClick={() => {
                const newConstraints = inputValueConstraints;
                newConstraints[keyInput] = valueInput === "true";
                setInputValueConstraints(newConstraints);
                setKeyInput("");
                setValueInput("");
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-plus" />
            </button>
          </div>
        );
      },
      Map: (values) => {
        return (
          <div>
            <label>Schema</label>
            <ul>
              {Object.entries(schema).map(([key, value]) => {
                return (
                  <li key={key}>
                    {key} : {JSON.stringify(value)}
                    <button
                      disabled={key === "name"}
                      onClick={() => {
                        const newSchema = { ...schema };
                        delete newSchema[key];
                        setSchema(newSchema);
                      }}
                    >
                      <FontAwesomeIcon icon="fa-solid fa-trash" />
                    </button>
                  </li>
                );
              })}
            </ul>
            <div>
              <input
                id="parameters-dropdown-values-schema-key"
                type="text"
                value={schemaKeyInput}
                onChange={(e) => setSchemaKeyInput(e.currentTarget.value)}
              />
              <select
                id="parameters-dropdown-values-schema-type"
                value={schemaTypeInput}
                onChange={(e) => setSchemaTypeInput(e.currentTarget.value)}
              >
                <option>Select an option...</option>
                <option key="String" value="String">
                  String
                </option>
                <option key="Number" value="Number">
                  Number
                </option>
                <option key="Boolean" value="Boolean">
                  True/False
                </option>
              </select>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  schema[schemaKeyInput] = schemaTypeInput;
                  setSchema(schema);
                  setSchemaKeyInput("");
                  setSchemaTypeInput("");
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </div>
            <label>Entries</label>
            <ul>{listItemsBuilder(values)}</ul>
            <div>
              {/* <input
                id="parameters-dropdown-values-entry-key"
                type="text"
                value={keyInput}
                onChange={(e) => setKeyInput(e.currentTarget.value)}
              /> */}
              {Object.entries(schema).map(([key, type]) => {
                const setterFn = (entryKey) => (currentVal) => {
                  const newVals = valueInput === "" ? {} : valueInput;
                  setValueInput({
                    ...newVals,
                    [entryKey]: currentVal,
                  });
                };

                return (
                  <div key={key}>
                    <label>{key}</label>
                    {typeInputsBuilder(
                      type,
                      `parameters-dropdown-values-entry-${key}`,
                      valueInput && valueInput[key],
                      setterFn(key)
                    )}
                  </div>
                );
              })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const newConstraints = inputValueConstraints;
                  console.log(newConstraints);
                  newConstraints.push(valueInput);
                  setInputValueConstraints(newConstraints);
                  setKeyInput("");
                  setValueInput("");
                }}
              >
                <FontAwesomeIcon icon="fa-solid fa-plus" />
              </button>
            </div>
          </div>
        );
      },
    };

    return typeMap[type](inputValueConstraints);
  };

  return (
    <div>
      <label htmlFor="parameters-dropdown-type">Value Type</label>
      <select
        id="parameters-dropdown-type"
        value={typeInput}
        onChange={(e) => {
          setInputValueConstraints([]);
          setTypeInput(e.currentTarget.value);
        }}
      >
        <option value="">Select a Value Type...</option>
        <option key="List" value="List">
          Simple List
        </option>
        <option key="String" value="String">
          String
        </option>
        <option key="Number" value="Number">
          Number
        </option>
        <option key="Boolean" value="Boolean">
          True/False
        </option>
        <option key="Map" value="Map">
          Map
        </option>
      </select>
      {typeInput && typeInput !== "" ? (
        valueInputsForDropdownType(typeInput)
      ) : (
        <></>
      )}
    </div>
  );
}

export default DropdownConstraints;
