import ComboBox from "../ComboBox";
import { Item } from "react-stately";

function Dropdown({ label, value, onChange, allowedValues }) {
  const changeHandler = (selectedKey) => {
    onChange(allowedValues.find((v) => v.name === selectedKey));
  };

  const props = {
    label,
    defaultInputValue: value?.name,
    defaultSelectedKey: value?.name,
    onSelectionChange: changeHandler,
    items: allowedValues,
  };

  return (
    <ComboBox {...props}>
      {(item) => <Item key={item.name}>{item.name}</Item>}
    </ComboBox>
  );
}

export default Dropdown;
