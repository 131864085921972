import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";

const EditDeviceModel = () => {
  let params = useParams();

  const [deviceModel, setDeviceModel] = useState({
    data: {},
    loading: true,
    error: null,
  });

  const [allParams, setAllParams] = useState([]);
  const [selectedParam, setSelectedParam] = useState("");

  const { callApi } = useApi();

  const handleAddParam = async (event) => {
    event.preventDefault();
    try {
      const options = {
        path: `/device_models/${params.deviceModelId}/parameters`,
        scope: "update:device_models:parameters",
        method: "PUT",
        body: selectedParam,
      };
      const { parameters } = await callApi(options).then((res) => res.json());
      setDeviceModel({
        ...deviceModel,
        data: { ...deviceModel.data, parameters },
      });
      setSelectedParam("");
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleRemoveParam = async (paramId, event) => {
    event.preventDefault();
    try {
      const options = {
        path: `/device_models/${params.deviceModelId}/parameters/${paramId}`,
        scope: "update:device_models:parameters",
        method: "DELETE",
      };
      await callApi(options);
      const parameters = deviceModel.data.parameters.filter(
        (p) => p.id !== paramId
      );
      setDeviceModel({
        ...deviceModel,
        data: { ...deviceModel.data, parameters },
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  const fetchDeviceModel = async () => {
    try {
      const options = {
        path: `/device_models/${params.deviceModelId}`,
        scope: "read:device_models",
      };
      const newModel = await callApi(options).then((res) => res.json());
      setDeviceModel({
        ...deviceModel,
        data: newModel,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDeviceModel({
        ...deviceModel,
        error,
        loading: false,
      });
    }
  };

  const fetchParameters = async () => {
    try {
      const options = {
        path: `/parameters`,
        scope: "list:parameters",
      };
      const parameters = await callApi(options).then((res) => res.json());
      setAllParams(parameters.filter((p) => p.category === "device model"));
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    fetchDeviceModel();
    fetchParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paramRows = () => {
    if (deviceModel.loading) {
      return (
        <tr>
          <td colSpan="5">Loading...</td>
        </tr>
      );
    }
    const params = deviceModel.data.parameters;
    if (!params) {
      return (
        <tr>
          <td colSpan="5">No parameters</td>
        </tr>
      );
    }
    const rows = [];
    for (const param of params) {
      rows.push(
        <tr key={param.id}>
          <td>{param.name}</td>
          <td>
            <button onClick={(event) => handleRemoveParam(param.id, event)}>
              Remove
            </button>
          </td>
        </tr>
      );
    }
    return rows;
  };

  const availableParams = () => {
    const existingParams = deviceModel.data?.parameters || [];
    return allParams.filter((p) => !existingParams.find((e) => e.id === p.id));
  };

  return (
    <div className="EditDeviceModel page">
      <h2>Parameters for {deviceModel.data.name}</h2>
      <form onSubmit={handleAddParam}>
        <select
          value={selectedParam}
          onChange={(e) => setSelectedParam(e.currentTarget.value)}
        >
          <option value="" key="">
            Select a Parameter...
          </option>
          {availableParams().map((p) => (
            <option value={p.id} key={p.id}>
              {p.name}
            </option>
          ))}
        </select>
        <input type="submit" value="Add" disabled={selectedParam === ""} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Parameter Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{paramRows()}</tbody>
      </table>
    </div>
  );
};

export default EditDeviceModel;
