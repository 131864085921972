import { GoogleMap, MarkerF } from "@react-google-maps/api";
import { useState } from "react";

function LocationInput({ label, value, onChange }) {
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = {
    lat: parseFloat(value?.latitude) || -28.02228,
    lng: parseFloat(value?.longitude) || 148.60345,
  };

  const [marker, setMarker] = useState();
  const [map, setMap] = useState();
  const [hideMap, setHideMap] = useState(true);
  const handleChange = (coord) => onChange({ ...value, ...coord });

  if (!value?.latitude || !value?.longitude) {
    // Lat/Long not set, so set the defaults so they will be saved even
    // if no changes are made.
    handleChange({ latitude: center.lat, longitude: center.lng });
  }

  return (
    <div>
      <label>{label}</label>
      {hideMap ? (
        <>
          <div>
            Latitude {center.lat}, Longitude {center.lng}
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setHideMap(false);
            }}
          >
            Change Location
          </button>
        </>
      ) : (
        <>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onCenterChanged={() => {
              const mapCenter = map?.getCenter();
              mapCenter && marker?.setPosition(mapCenter);
            }}
            onLoad={(m) => setMap(m)}
            clickableIcons={false}
            options={{
              mapTypeId: "hybrid",
              streetViewControl: false,
              keyboardShortcuts: false,
              draggableCursor: "crosshair",
            }}
          >
            {map && (
              <MarkerF visible position={center} onLoad={(m) => setMarker(m)} />
            )}
          </GoogleMap>
          <button
            onClick={(e) => {
              e.preventDefault();
              const pos = {
                latitude: parseFloat(marker.getPosition().lat().toFixed(5)),
                longitude: parseFloat(marker.getPosition().lng().toFixed(5)),
              };
              handleChange(pos);
              setHideMap(true);
            }}
          >
            Save Location
          </button>
        </>
      )}
    </div>
  );
}

export default LocationInput;
