function NumberConstraints({
  inputValueConstraints,
  setInputValueConstraints,
}) {
  const { maxFractionDigits = 2, minValue, maxValue } = inputValueConstraints;

  // Ensure defaults are saved even if no other change is made
  if (!inputValueConstraints.maxFractionDigits) {
    setInputValueConstraints({ ...inputValueConstraints, maxFractionDigits });
  }

  const handleChange = (change) => {
    console.log(JSON.stringify(change));
    setInputValueConstraints({ ...inputValueConstraints, ...change });
  };
  return (
    <div>
      <div>
        <label htmlFor="parameters-number-max-fraction-digits">
          Maximum Fraction Digits
        </label>
        <input
          type="number"
          min={0}
          defaultValue={maxFractionDigits}
          onChange={(e) =>
            handleChange({ maxFractionDigits: e.currentTarget.value })
          }
        />
      </div>
      <div>
        <label htmlFor="parameters-number-min-value">Minimum Value</label>
        <input
          type="number"
          defaultValue={minValue}
          onChange={(e) => handleChange({ minValue: e.currentTarget.value })}
        />
      </div>
      <div>
        <label htmlFor="parameters-number-max-value">Maximum Value</label>
        <input
          type="number"
          defaultValue={maxValue}
          onChange={(e) => handleChange({ maxValue: e.currentTarget.value })}
        />
      </div>
    </div>
  );
}

export default NumberConstraints;
