import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import errorLogger from "../../../helpers/errorLogger";
import useApi from "../../../helpers/useApi";
import { useAuth0 } from "@auth0/auth0-react";
import RecipientsManager from "../../elements/RecipientsManager";

const EditAlert = () => {
  const { user } = useAuth0();
  const userRoles = user?.cyf_roles;
  const isAdmin = userRoles && userRoles.includes("Admin");

  const { deviceId, alertId } = useParams();

  const [conditionInput, setConditionInput] = useState("");
  const [targetFieldInput, setTargetFieldInput] = useState("");
  const [thresholdInput, setThresholdInput] = useState("");
  const [hitsInput, setHitsInput] = useState("1");
  const [resetDifferenceInput, setResetDifferenceInput] = useState("0");
  const [recipientsInput, setRecipientsInput] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [device, setDevice] = useState({
    data: {},
    loading: true,
    error: null,
  });
  const [alert, setAlert] = useState({
    data: {},
    loading: true,
    error: null,
  });
  const [recipientsList, setRecipientsList] = useState({
    data: [],
    loading: true,
    error: null,
  });

  const { callApi } = useApi();
  const navigate = useNavigate();

  const handleCancel = () => navigate(`/devices/${deviceId}/alerts`);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const requestBody = {
        condition: conditionInput,
        targetField: targetFieldInput,
        threshold: parseFloat(thresholdInput),
        hits: parseInt(hitsInput, 10),
        resetDifference: parseFloat(resetDifferenceInput),
        recipients: recipientsInput,
        message: messageInput,
      };
      const options = {
        path: `/alerts/${alertId}`,
        scope: "update:alerts",
        method: "PUT",
        body: JSON.stringify(requestBody),
      };
      await callApi(options).then((res) => res.json());
      navigate(`/devices/${deviceId}/alerts`);
    } catch (error) {
      errorLogger(error);
    }
  };

  const fetchDevice = async () => {
    try {
      const options = {
        path: `/devices/${deviceId}`,
        scope: "read:devices",
      };
      const newDevice = await callApi(options).then((res) => res.json());

      setDevice({
        ...device,
        data: newDevice,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setDevice({
        ...device,
        error,
        loading: false,
      });
    }
  };

  const fetchAlert = async () => {
    try {
      const options = {
        path: `/alerts/${alertId}`,
        scope: "read:alerts",
      };
      const newAlert = await callApi(options).then((res) => res.json());

      setAlert({
        ...alert,
        data: newAlert,
        error: null,
        loading: false,
      });
      setConditionInput(newAlert.condition);
      setTargetFieldInput(newAlert.targetField);
      setThresholdInput(newAlert.threshold);
      setHitsInput(newAlert.hits);
      setResetDifferenceInput(newAlert.resetDifference);
      setRecipientsInput(newAlert.recipients);
      setMessageInput(newAlert.message);
    } catch (error) {
      errorLogger(error);
      setAlert({
        ...alert,
        error,
        loading: false,
      });
    }
  };

  const fetchRecipients = async () => {
    try {
      const options = {
        path: `/alerts/available_recipients`,
        scope: "list:alerts:recipients",
      };
      const recipients = await callApi(options).then((res) => res.json());

      setRecipientsList({
        ...recipientsList,
        data: recipients,
        error: null,
        loading: false,
      });
    } catch (error) {
      errorLogger(error);
      setRecipientsList({
        ...recipientsList,
        error,
        loading: false,
      });
    }
  };

  useEffect(() => {
    fetchDevice();
    fetchAlert();
    fetchRecipients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const variableOptions = () => {
    // {display: "Percent Full", field: "percent_full"}
    const variableMap = {
      "Channel Level": ["depth", "battery_voltage"],
      "Water Tank": ["percent_full", "depth", "battery_voltage"],
      "Vertical Tank": ["percent_full", "depth", "battery_voltage", "volume"],
      "Horizontal Tank": ["volume", "percent_full", "depth", "battery_voltage"],
      "Elliptical Tank": ["volume", "percent_full", "depth", "battery_voltage"],
      "Electric Fence Sensor": ["fence_voltage", "battery_voltage"],
      "Engine Monitor": [
        "engine_hours",
        "coolant_temp",
        "coolant_level",
        "oil_pressure_kpa",
        "percent_load",
        "boost_pressure_kpa",
      ],
      "Nonlinear Storage": [
        "depth",
        "volume_megalitres",
        "percent_full",
        "battery_voltage",
      ],
      "Pressure Sensor": ["pressure_psi", "pressure_bar", "battery_voltage"],
      "Rain Gauge": ["battery_voltage"],
      Trough: ["depth", "battery_voltage"],
      "Weather Station": [
        "temp",
        "wind_speed",
        "wind_gust",
        "wind_direction",
        "pressure",
        "humidity",
        "battery_voltage",
      ],
    };

    return variableMap[device.data.deviceTypeName] || [];
  };

  // const bufferWordingMap = {
  //   high: "less than",
  //   low: "greater than",
  // };
  if (alert.loading) {
    return (
      <div className="create-alerts">
        <h2>...Loading...</h2>
      </div>
    );
  } else {
    return (
      <div className="create-alerts">
        <h2>Edit Alert</h2>
        <h3>Device: {device.data.name}</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="alert-targetField">Variable</label>
            <select
              id="alert-targetField"
              value={targetFieldInput}
              onChange={(e) => setTargetFieldInput(e.currentTarget.value)}
            >
              <option key="empty-variable">Select variable...</option>
              {variableOptions().map((v) => (
                <option key={v.toLowerCase()} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="alert-condition">Condition</label>
            <select
              id="alert-condition"
              value={conditionInput}
              onChange={(e) => setConditionInput(e.currentTarget.value)}
            >
              <option key="empty-condition">Select condition...</option>
              <option key="low" value="low">
                Less Than
              </option>
              <option key="high" value="high">
                Greater Than
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="alert-threshold">Trigger value</label>
            <input
              id="alert-threshold"
              type="number"
              value={thresholdInput}
              onChange={(e) => setThresholdInput(e.currentTarget.value)}
            />
          </div>
          <div>
            <label htmlFor="alert-hits">
              {conditionInput
                ? `Number of ${conditionInput} reads before sending alert`
                : `...`}
            </label>
            <input
              id="alert-hits"
              type="number"
              value={hitsInput}
              onChange={(e) => setHitsInput(e.currentTarget.value)}
            />
          </div>
          <div>
            <label htmlFor="alert-reset-difference">
              {conditionInput ? (
                <>
                  {`Buffer before alarm reset`}
                  {` (If unsure leave default)`}
                </>
              ) : (
                `...`
              )}
            </label>
            <input
              id="alert-reset-difference"
              type="number"
              value={resetDifferenceInput}
              onChange={(e) => setResetDifferenceInput(e.currentTarget.value)}
            />
          </div>
          <div>
            <label htmlFor="alert-message">{`Custom message (if required)`}</label>
            <input
              id="alert-message"
              type="text"
              value={messageInput}
              onChange={(e) => setMessageInput(e.currentTarget.value)}
            />
          </div>
          <div>
            <label htmlFor="alert-recipients">Recipients</label>
            {recipientsList.loading ? (
              <span>Loading...</span>
            ) : (
              <RecipientsManager
                users={recipientsList.data}
                recipients={recipientsInput}
                onRecipientsChange={setRecipientsInput}
              />
            )}
          </div>
          <input type="submit" value="Save" />
          <input type="button" value="Cancel" onClick={handleCancel} />
        </form>
      </div>
    );
  }
};

export default EditAlert;
