import { useAuth0 } from "@auth0/auth0-react";

class ResponseError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

const useApi = () => {
  const { getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();

  const callApi = async (options) => {
    const audience = process.env.REACT_APP_AUDIENCE;
    const { scope, path, query, ...fetchOptions } = options;
    const queryString = query
      ? `?${new URLSearchParams(query).toString()}`
      : "";
    const url = `${process.env.REACT_APP_API_URL}${path}${queryString}`;

    const authOptions = { audience: audience, scope: scope || "default" };
    const accessToken = await getAccessTokenSilently(authOptions).catch(
      (err) => {
        getAccessTokenWithPopup(authOptions);
      }
    );

    const response = await fetch(url, {
      method: "GET",
      ...fetchOptions,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    if (!response.ok) {
      throw new ResponseError("API Request Failed", response);
    }

    return response;
  };

  return { callApi };
};

export default useApi;
