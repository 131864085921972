import { DismissButton, Overlay, usePopover } from "react-aria";

function Popover({ children, state, ...props }) {
  let { popoverProps } = usePopover(props, state);

  return (
    <Overlay>
      <div
        {...popoverProps}
        ref={props.popoverRef}
        style={{
          ...popoverProps.style,
          background: "lightgray",
          border: "1px solid gray",
        }}
      >
        {children}
        <DismissButton onDismiss={state.close} />
      </div>
    </Overlay>
  );
}

export default Popover;
