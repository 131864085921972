import { useEffect, useState } from "react";
import errorLogger from "../../helpers/errorLogger";
import useApi from "../../helpers/useApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Manufacturers = () => {
  const [manufList, setManufList] = useState({
    data: [],
    loading: true,
    error: null,
  });
  const [inputName, setInputName] = useState("");

  const { callApi } = useApi();

  const resetInputs = () => {
    setInputName("");
  };

  const handleCancel = (_event) => resetInputs();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const requestBody = {
        name: inputName,
      };
      const options = {
        path: "/manufacturers",
        scope: "create:manufacturers",
        method: "POST",
        body: JSON.stringify(requestBody),
      };
      const newManuf = await callApi(options).then((res) => res.json());
      const newList = [...manufList.data, newManuf];
      setManufList({
        ...manufList,
        data: newList,
      });
      resetInputs();
    } catch (error) {
      errorLogger(error);
    }
  };

  const handleDelete = async (manufacturer, event) => {
    event.preventDefault();

    try {
      const options = {
        path: `/manufacturers/${manufacturer.id}`,
        scope: "delete:manufacturers",
        method: "DELETE",
      };
      await callApi(options);
      const newList = manufList.data.filter((m) => m.id !== manufacturer.id);
      setManufList({
        ...manufList,
        data: newList,
      });
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    const updateManufList = async () => {
      try {
        const options = {
          path: "/manufacturers",
          scope: "list:manufacturers",
        };
        const newList = await callApi(options).then((res) => res.json());
        setManufList({
          ...manufList,
          data: newList,
          error: null,
          loading: false,
        });
      } catch (error) {
        errorLogger(error);
        setManufList({
          ...manufList,
          error,
          loading: false,
        });
      }
    };

    updateManufList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionBuilder = (m) => {
    return (
      <td>
        <button onClick={(e) => handleDelete(m, e)}>
          <FontAwesomeIcon icon="fa-solid fa-trash" />
        </button>
      </td>
    );
  };

  const manufRows = manufList.loading ? (
    <tr>
      <td colSpan="2">Loading...</td>
    </tr>
  ) : (
    manufList.data
      ?.filter((m) => m?.name?.includes(inputName))
      .map((m) => (
        <tr key={m.id}>
          <td>{m.name}</td>
          {actionBuilder(m)}
        </tr>
      ))
  );

  return (
    <div className="Manufacturers page">
      <h2>Manufacturers</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="manufacturers-name">Name</label>
          <input
            id="manufacturers-name"
            type="text"
            value={inputName}
            onChange={(e) => setInputName(e.currentTarget.value)}
          />
        </div>
        <input type="submit" value="Save" />
        <input type="button" value="Cancel" onClick={handleCancel} />
      </form>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{manufRows}</tbody>
      </table>
    </div>
  );
};

export default Manufacturers;
